<template>
  <page-layout
    :title="$t('user_management.expenses_details_page.title')"
    :has-back-button="true"
    id="expenseDetailsPage"
    v-loading="loading"
  >
    <template
      v-if="$auth.isOspAdmin()"
      #header-actions
    >
      <base-button
        :label="$t('user_management.expenses_details_page.button_correction')"
        @click="handleCorrect"
      ></base-button>
      <base-button
        type="primary"
        :label="$t('user_management.expenses_details_page.button_refund')"
        @click="handleRefund"
      ></base-button>
    </template>
    <el-form
      ref="form"
      :model="form"
      :key="key"
      @submit.prevent.native="() => {}"
      id="appUserExpenseForm"
      label-position="top"
    >
      <base-section :title="$t('general.label.budget')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.label.budget')"
              :value="summary.budgetName"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section :title="$t('general.label.price')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-currency-input
              :label="$t('general.label.price_vat_incl')"
              :value="price.priceVatIncl"
              :readonly="true"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-currency-input
              :label="$t('general.label.price_vat_excl')"
              :value="price.priceVatExcl"
              :readonly="true"
            ></base-currency-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-currency-input
              :label="$t('general.label.price_vat')"
              :value="price.priceVat"
              :readonly="true"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.view_app_users_page.add_expense.input_vat_perc.label')"
              :value="(price.vatPercentage || 0) + '%'"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('finance.company_budget_page.dialog_promocode.input_promocode_label')"
              :value="price.promoCode"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-currency-input
              :label="$t('general.label.promocode_vat_incl')"
              :value="price.promoCodeValueVatIncl"
              :readonly="true"
            ></base-currency-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section :title="$t('user_management.expenses_details_page.event_details_section.title')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('partners_management.add_partner_page.partner_type_labels.MRP')"
              :value="summary.mrpName"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('general.label.resource')"
              :value="summary.resourceName"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.event_time_label')"
              :value="summary.createdOn && $dateFormat(summary.eventTimestamp, 'yyyy-LL-dd HH:mm:ss')"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.event_created_on_label')"
              :value="summary.createdOn && $dateFormat(summary.createdOn, 'yyyy-LL-dd HH:mm:ss')"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.input_label.description')"
              :value="summary.description"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.booking_id_label')"
              :value="summary.bookingId"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.usage_reason_label')"
              :value="summary.usageReason"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.external_reference_label')"
              :value="summary.externalReference"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <base-input
              :label="$t('user_management.expenses_details_page.event_details_section.event_osp_id_label')"
              :value="summary.eventOspId"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
      </base-section>

      <base-section :title="$t('user_management.expenses_details_page.user_details_section.title')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.label.user')"
              :value="summary.userName"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('corporate_accounts_management.view_all_page.title')"
              :value="summary.corporateAccountName"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.label.partner')"
              :value="summary.mspName"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.user_details_section.identification_field_label')"
              :value="summary.identificationField"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.user_details_section.user_contract_id_label')"
              :value="summary.userContractId"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.user_details_section.corporate_contract_id_label')"
              :value="summary.corporateAccountContractId"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
      </base-section>

      <base-section :title="$t('user_management.expenses_details_page.other_section.title')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.other_section.not_billed_label')"
              :value="summary.billed ? $t('general.no') : $t('general.yes')"
              :readonly="true"
            ></base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('user_management.expenses_details_page.other_section.not_settled_label')"
              :value="summary.settled ? $t('general.no') : $t('general.yes')"
              :readonly="true"
            ></base-input>
          </el-col>
        </el-row>
      </base-section>

      <base-section :title="$t('user_management.expenses_details_page.details_section.title')" v-if="properties.length">
        <template v-for="(chunk, index) in properties">
          <el-row :key="'chunk-' + index" :gutter="24">
            <template v-for="(property, idx) in chunk">
              <el-col :key="'property-' + idx" :span="12">
                <base-input
                  :label="$trans(property.label)"
                  :value="property.value"
                  :readonly="true"
                ></base-input>
              </el-col>
            </template>
          </el-row>
        </template>
      </base-section>
    </el-form>

    <el-dialog
      :title="$t('user_management.expenses_details_page.refund_dialog.title')"
      :visible.sync="refundExpense.visible"
      @close="refundExpense.visible = false"
    >
      <el-form
        :key="refundExpense.key"
        :model="refundExpense.form"
        label-position='top'
      >
        <base-input
          type="textarea"
          :label="$t('user_management.expenses_details_page.refund_dialog.remark_label')"
          maxlength="140"
          :autosize="{minRows: 5, maxRows: 8}"
          v-model="refundExpense.form.reason"
          :show-word-limit="true"
          :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
          :max-width="400"
        ></base-input>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="refundExpense.visible = false"
        ></base-button>
        <base-button
          type="primary"
          :label="$t('user_management.expenses_details_page.refund_dialog.submit_button')"
          @click="refund"
        />
  </span>
    </el-dialog>

    <el-dialog
      :title="$t('user_management.expenses_details_page.correct_dialog.title')"
      :visible.sync="correctExpense.visible"
      @close="correctExpense.visible = false"
    >
      <el-form
        :key="correctExpense.key"
        :model="correctExpense.form"
        label-position='top'
      >
        <base-radio
          :label="'ADD'"
          :value="correctExpense.form.correctType"
          @input="changeCorrectType"
          style="margin-bottom: -5px"
        >
          {{$t('user_management.expenses_details_page.correct_dialog.add_cost_label')}}
        </base-radio>
        <base-radio
          :label="'SUBTRACT'"
          :value="correctExpense.form.correctType"
          @input="changeCorrectType"
        >
          {{$t('user_management.expenses_details_page.correct_dialog.refund_cost_label')}}
        </base-radio>
        <el-row :gutter="24" style="max-width: 424px">
          <el-col :span="12">
            <base-currency-input
              :label="$t('general.label.price_vat_incl')"
              :validations="$v.correctExpense.form.amountVatIncl"
              v-model="correctExpense.form.amountVatIncl"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('user_management.view_app_users_page.add_expense.input_vat_perc.label')"
              :required="true"
              :validations="$v.correctExpense.form.vatPercentage"
              ref="vatPercentage"
              prop="vatPercentage"
              :items="vatPercentages"
              v-model="correctExpense.form.vatPercentage"
              name="vatPercentage"
            />
          </el-col>
        </el-row>
        <base-input
          type="textarea"
          :label="$t('user_management.expenses_details_page.correct_dialog.remark_label')"
          maxlength="140"
          :autosize="{minRows: 5, maxRows: 8}"
          v-model="correctExpense.form.remark"
          :show-word-limit="true"
          :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
          :max-width="400"
        ></base-input>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="correctExpense.visible = false"
        ></base-button>
        <base-button
          type="primary"
          :label="$t('user_management.expenses_details_page.correct_dialog.submit_button')"
          @click="correct"
        />
  </span>
    </el-dialog>
  </page-layout>
</template>

<script>
import api from '@/api'

import PageLayout from '@/layouts/PageLayout'
import { chunk } from 'lodash'
import { required } from 'vuelidate/lib/validators'
// import Role from '@/app/services/RolesService/Role';

const correctExpenseDefault = () => {
  return {
    remark: null,
    correctType: 'ADD',
    amountVatIncl: null,
    vatPercentage: 0
  }
}

export default {
  name: 'AppUserExpensesViewDetails',
  components: {
    PageLayout
  },
  props: ['eventOspId', 'userOspId'],
  data () {
    return {
      key: null,
      event: {},
      form: {},
      loading: false,
      refundExpense: {
        visible: false,
        key: null,
        form: {
          reason: null
        }
      },
      correctExpense: {
        visible: false,
        key: null,
        form: correctExpenseDefault()
      },
      vatPercentages: [
        {
          label: '0%',
          value: 0
        },
        {
          label: '6%',
          value: 6
        },
        {
          label: '12%',
          value: 12
        },
        {
          label: '21%',
          value: 21
        }
      ]
    }
  },
  validations: {
    correctExpense: {
      form: {
        amountVatIncl: {
          required
        },
        vatPercentage: {
          required
        }
      }
    }
  },
  computed: {
    summary () {
      return this.event.summary || {}
    },
    price () {
      return this.summary.price || {}
    },
    properties () {
      return chunk(this.event.properties, 2)
    }
  },
  watch: {},
  methods: {
    fetchData () {
      this.$api.event.get({ ospId: this.$route.params.eventOspId }, {
        onSuccess: event => {
          this.event = event
          this.key = this.$key()
        }
      })
    },
    handleCorrect () {
      this.correctExpense.form = correctExpenseDefault()
      this.$v.correctExpense.form.$reset()
      this.correctExpense.key = this.$key()
      this.correctExpense.visible = true
    },
    handleRefund () {
      this.refundExpense.form.reason = ''
      this.refundExpense.key = this.$key()
      this.refundExpense.visible = true
    },
    refund () {
      this.loading = true
      this.refundExpense.visible = false
      this.$api.event.refund({
        eventOspId: {
          ospId: this.eventOspId
        },
        remark: this.refundExpense.form.reason
      }, {
        successNotification: this.$t('user_management.expenses_details_page.refund_success_notification'),
        onFinally: () => {
          this.loading = false
        }
      })
    },
    correct () {
      this.$v.correctExpense.form.$touch()
      if (this.$v.correctExpense.form.$invalid) {
        return false
      }
      this.loading = true
      this.correctExpense.visible = false
      this.$api.event.correct({
        eventOspId: {
          ospId: this.eventOspId
        },
        ...this.correctExpense.form
      }, {
        successNotification: this.$t('user_management.expenses_details_page.correct_success_notification'),
        onFinally: () => {
          this.loading = false
        }
      })
    },
    changeCorrectType (v) {
      this.correctExpense.form.correctType = v
      this.correctExpense.key = this.$key()
    }
  },
  mounted () {
    this.key = this.$key()
  },
  beforeRouteEnter (to, from, next) {
    api.event.get({ ospId: to.params.eventOspId }, {
      onSuccess: event => {
        next(vm => {
          vm.event = event
        })
      },
      onError: () => {
        next(from)
      }
    })
  }
}
</script>
