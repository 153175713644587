var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{attrs:{"title":_vm.$t('user_management.section.expense_details.title'),"with-border":false}},[_c('el-form',{key:_vm.key,ref:"form",attrs:{"label-position":"top","model":_vm.form,"autocomplete":"off"},nativeOn:{"submit":function($event){$event.preventDefault();return null.apply(null, arguments)}}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{key:_vm.priceVatInclKey,ref:"priceVatIncl",attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_amount.label'
            ),"required":true,"prop":"priceVatIncl","validations":_vm.$v.form.priceVatIncl,"name":"startpriceVatIncl","disabled":!_vm.edit},model:{value:(_vm.form.priceVatIncl),callback:function ($$v) {_vm.$set(_vm.form, "priceVatIncl", $$v)},expression:"form.priceVatIncl"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-select',{ref:"vatPercentage",attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_vat_perc.label'
            ),"required":true,"prop":"vatPercentage","items":_vm.vatPercentages,"validations":_vm.$v.form.vatPercentage,"name":"vatPercentage","disabled":!_vm.edit},model:{value:(_vm.form.vatPercentage),callback:function ($$v) {_vm.$set(_vm.form, "vatPercentage", $$v)},expression:"form.vatPercentage"}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_description.label'
            ),"name":"description","required":true,"placeholder":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_description.placeholder',
              { number: 140 }
            ),"validations":_vm.$v.form.description,"maxlength":"140","show-word-limit":true,"disabled":!_vm.edit},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-select',{ref:"budget",attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_budget.label'
            ),"required":true,"prop":"budgets","items":_vm.budgetsList,"disabled":true,"validations":_vm.$v.form.budgetOspId,"name":"budgets"},model:{value:(_vm.form.budgetOspId),callback:function ($$v) {_vm.$set(_vm.form, "budgetOspId", $$v)},expression:"form.budgetOspId"}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-datepicker',{attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_end_date.label'
            ),"required":true,"validations":_vm.$v.form.endDate,"clearable":false,"picker-options":_vm.datePickerOptions,"disabled":!_vm.edit},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-select',{ref:"frequency",attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_frequency.label'
            ),"required":true,"prop":"frequency","disabled":true,"items":_vm.frequencies,"validations":_vm.$v.form.recurrence,"name":"frequency"},model:{value:(_vm.form.recurrence),callback:function ($$v) {_vm.$set(_vm.form, "recurrence", $$v)},expression:"form.recurrence"}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-datepicker',{attrs:{"label":_vm.$t(
              'user_management.view_app_users_page.add_expense.input_date.label'
            ),"disabled":true,"clearable":false,"picker-options":_vm.endDatePickerOptions},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-select',{ref:"mobilityMode",attrs:{"label":_vm.$t('general.label.mobility_mode'),"required":true,"prop":"mobilityMode","disabled":true,"items":_vm.mobilityModes,"name":"mobilityMode"},scopedSlots:_vm._u([{key:"option",fn:function({ items }){return _vm._l((items),function(item){return _c('el-option',{key:item.mobilityMode,staticClass:"dashboard-user-selection",attrs:{"label":_vm.$trans(item.label),"value":item.mobilityMode}})})}}]),model:{value:(_vm.form.mobilityMode),callback:function ($$v) {_vm.$set(_vm.form, "mobilityMode", $$v)},expression:"form.mobilityMode"}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[(_vm.form.priceFirstOccurrenceVatIncl)?_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.expenses.firstAmount'),"name":"priceFirstOccurrenceVatIncl","disabled":true},model:{value:(_vm.form.priceFirstOccurrenceVatIncl),callback:function ($$v) {_vm.$set(_vm.form, "priceFirstOccurrenceVatIncl", $$v)},expression:"form.priceFirstOccurrenceVatIncl"}}):_vm._e()],1),_c('el-col',{attrs:{"span":12}},[(_vm.form.priceLastOccurrenceVatIncl)?_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.expenses.lastAmount'),"name":"priceLastOccurrenceVatIncl","disabled":true},model:{value:(_vm.form.priceLastOccurrenceVatIncl),callback:function ($$v) {_vm.$set(_vm.form, "priceLastOccurrenceVatIncl", $$v)},expression:"form.priceLastOccurrenceVatIncl"}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }