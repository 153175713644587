<template>
  <page-layout
    :title="$t('user_management.view_app_users_page.edit_expense.title')"
    :has-back-button="true"
  >
    <template #header-actions>
      <base-button
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="saving"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('user_management.view_app_users_page.edit_expense.alert_text', { username })"/>
    </el-alert>
    <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off">
      <expense-details-edit-section
        ref="expenseDetails"
        id="expenseDetails"
        :edit="isEditable"
        :budgets="budgets"
        v-model="form"
      />
    </el-form>
  </page-layout>
</template>

<script>
import store from '@/store'
import PageLayout from '../layouts/PageLayout'
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import ExpenseDetailsEditSection from '@/components/sections/ExpenseDetailsEditSection'

export default {
  name: 'AppUserExpensesEdit',
  components: {
    PageLayout,
    ExpenseDetailsEditSection
  },
  props: ['userOspId'],
  data () {
    return {
      saving: false,
      budgetType: null,
      form: {
        priceVatIncl: 0,
        vatPercentage: null,
        description: '',
        frequency: null,
        eventTimestamp: null,
        endDate: null,
        budgetOspId: null,
        currency: 'EUR',
        mobilityMode: 'OTHER_PURCHASES'
      },
      key: '',
      budgetTypeKey: 'budgetTypeKey'
    }
  },
  computed: {
    ...mapGetters({
      employeeName: 'user/employeeName',
      userBudgets: 'budget/userBudgets'
    }),
    username () {
      return this.employeeName(this.userOspId)
    },
    budgets () {
      return this.userBudgets(this.userOspId)
    },
    isEditable () {
      return this.form.editable
    }
  },
  methods: {
    ...mapActions({
      createBudget: 'budget/createBudget',
      fetchTypes: 'recurringExternalPurchase/fetchTypes'
    }),
    handleSaveChanges () {
      this.$refs.expenseDetails.$v.form.$touch()
      if (this.$refs.expenseDetails.$v.form.$invalid) {
        this.$scrollTo('#expenseDetails')
        return false
      }
      // console.log(this.form)
      this.saving = true

      return this.$api.recurringExternalPurchase.update(this.form, {
        onSuccess: () => {
          this.$router.back()
        },
        successNotification: this.$t('user_management.view_app_users_page.edit_expense.success_notification')
      })
    },
    setData (recurringPurchase) {
      this.form = recurringPurchase
      this.key = this.$key()
    }
  },
  mounted () {
    this.key = this.$key()
  },
  async beforeRouteEnter (to, from, next) {
    const userOspId = to.params.userOspId
    const recurrenceOspId = to.params.recurrenceOspId
    const promises = []
    if (!store.getters['budget/userBudgets'](userOspId).length) {
      promises.push(store.dispatch('budget/getUserBudgets', { ospId: userOspId }))
    }
    if (!store.getters['recurringExternalPurchase/types'].length) {
      promises.push(store.dispatch('recurringExternalPurchase/fetchTypes'))
    }
    if (!store.getters['general/mobilityModes'].length) {
      promises.push(store.dispatch('general/getMobilityModes'))
    }
    await Promise.all(promises)
    api.recurringExternalPurchase.get({ ospId: recurrenceOspId }, {
      onSuccess: response => {
        next(vm => vm.setData(response))
      }
    })
  }
}
</script>

<style lang="scss">
</style>
